import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import { SEO } from "../../components/seo";
import Feature from "../../components/Feature";
import FeaturesSummary from "../../components/productPage/featuresSummary";

const ForCrewedYachtsPage = ({ data }) => {
  if (!data || !data.file) {
    return <p>Image not found</p>;
  }

  return (
    <Layout pageTitle="Floatist For Crewed Yachts">
      <Feature
        title="Optimize Your Yacht Operations with Floatist"
        subtitle="Operating a yacht involves a complex array of tasks and responsibilities, requiring flawless coordination between everyone on board. At Floatist, we recognize the unique demands of managing a yacht and offer tailored solutions that enhance efficiency, reduce administrative burden, and elevate the overall crew experience."
        imageData={data.file.childImageSharp.gatsbyImageData}
        textSide="right"
      />
      <FeaturesSummary />
    </Layout>
  );
};

export default ForCrewedYachtsPage;

export const query = graphql`
  query {
    file(relativePath: { eq: "yacht.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export const Head = () => {
  return (
    <SEO description="Discover Floatist, the ultimate yacht management solution designed to streamline operations and enhance communication among yacht owners, captains, and service providers. Experience unparalleled efficiency and transparency in every aspect of yacht management with Floatist's innovative platform."></SEO>
  );
};
